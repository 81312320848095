import React from 'react';
import PropTypes from 'prop-types';

const PageHeading = props => {
  return <h2 className="text-3xl leading-tight">{props.children}</h2>;
};

PageHeading.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageHeading;
