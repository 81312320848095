import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import Header from './Header';
import Footer from './Footer';

const Layout = props => {
  const { children, gitAuthorTime, isPost, title } = props;

  const {
    site: {
      siteMetadata: { siteTitle, siteUrl, siteDescription }
    }
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle: title
          siteUrl
          siteDescription: description
        }
      }
    }
  `);
  const domain = siteUrl.replace(/^https?:\/\/|\/$/, '');

  return (
    <>
      <Helmet
        titleTemplate={`%s - ${siteTitle} - ${domain}`}
        defaultTitle={`${siteTitle} - ${domain}`}
      >
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={siteDescription} />
        {siteTitle && <meta property="og:site_name" content={siteTitle} />}
        {title && <meta property="og:title" content={title} />}
        {isPost && <meta property="og:type" content="article" />}
      </Helmet>
      <div className="mt-5 mb-10 sm:my-20 sm:px-10 flex justify-center">
        <div className="w-full max-w-4xl">
          <div className="p-10 sm:bg-white sm:shadow sm:rounded-lg">
            <Header siteTitle={siteTitle} />
            <main className="mt-12">{children}</main>
          </div>
          <Footer gitAuthorTime={gitAuthorTime} />
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  gitAuthorTime: PropTypes.string,
  isPost: PropTypes.bool,
  title: PropTypes.string
};

Layout.defaultProps = {
  isPost: false
};

export default Layout;
