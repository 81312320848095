import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

const Footer = props => {
  const { gitAuthorTime } = props;

  return (
    <footer className="mt-12 px-10 sm:px-0 text-gray-500 text-center">
      <p>
        <a
          href="https://creativecommons.org/licenses/by/4.0/"
          title="Creative Commons Attribution 4.0 International License"
        >
          CC BY 4.0
        </a>
      </p>
      {gitAuthorTime && (
        <p>
          <em title="Last modified">
            {format(parseISO(gitAuthorTime), 'eee, d MMM yyyy HH:mm:ss zzzz')}
          </em>
        </p>
      )}
    </footer>
  );
};

Footer.propTypes = {
  gitAuthorTime: PropTypes.string
};

export default Footer;
