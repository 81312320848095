import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

const PubDate = props => {
  const { date } = props;
  const parsedDate = parseISO(date);

  return (
    <time
      className="pubdate"
      dateTime={format(parsedDate, 'yyyy-MM-dd')}
      title={`Published on ${format(parsedDate, 'PPppp')}`}
    >
      {format(parsedDate, 'PP')}
    </time>
  );
};

PubDate.propTypes = {
  date: PropTypes.string.isRequired
};

export default PubDate;
