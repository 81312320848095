import React from 'react';
import PropTypes from 'prop-types';

import PubDate from '../components/PubDate';

const PostMeta = props => {
  const { post } = props;

  return (
    <p className="mt-1 text-gray-600">
      <PubDate date={post.frontmatter.date} />
    </p>
  );
};

PostMeta.propTypes = {
  post: PropTypes.shape({
    frontmatter: PropTypes.shape({
      date: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default PostMeta;
